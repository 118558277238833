import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LoginView"),
  },
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MainView"),
    redirect: "/links",
    children: [
      {
        path: "/domains",
        name: "domains",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../components/Domains/DomainsView"
          ),
      },
      {
        path: "/links",
        name: "links",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../components/Links/LinksView"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  let user = JSON.parse(localStorage.getItem("user"));
  if (!user && to.path !== "/login") {
    next("/login");
    return;
  }

  if (to.path === "/login" && user) {
    next("/");
    return;
  }

  next();
});

export default router;
