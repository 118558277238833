<template>
  <v-app>
    <v-main>
      <router-view />
      <notifications group="foo" />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
  created() {
    window.getApp = this;
  },
};
</script>

<style lang="scss">
@import "styles/main.scss";
</style>
